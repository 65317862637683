import {
  updateSchool,
  getSchool,
  getSchoolEntities,
  getSchoolTeams,
} from "api/schools";
import { School } from "models/School";
import { SchoolEntity } from "models/SchoolEntity";
import { Team } from "models/Team";
import { useCallback, useEffect, useState } from "react";

export default function useSchool(id: string) {
  const [school, setSchool] = useState<School>();
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState<SchoolEntity[]>([]);
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);

  const update = useCallback(
    (values: Partial<School>) => {
      const observable = updateSchool(id, values);

      observable.subscribe(({ data }) => {
        setSchool(data);
      });

      return observable;
    },
    [id, school]
  );

  const fetchEntities = useCallback(() => {
    setEntitiesLoading(true);
    getSchoolEntities(id).subscribe(({ data }) => {
      setEntitiesLoading(false);
      setEntities(data.entities || []);
    });
  }, [id, school]);

  useEffect(() => {
    getSchool(id).subscribe(({ data }) => {
      setLoading(false);
      setSchool(data);
    });
    fetchEntities();

    getSchoolTeams(id).subscribe(({ data }) => {
      setTeams(data.teams || []);
    });
  }, []);

  return {
    loading,
    school,
    update,
    entities,
    teams,
    fetchEntities,
    entitiesLoading,
  };
}
