import {
  getGetEntityPaySettings,
  getSchoolEntities,
  updateEntityPaySettings,
} from "api/schoolEntities";
import { PaySetting, SchoolEntity } from "models/SchoolEntity";
import { useCallback, useEffect, useState } from "react";

interface SchoolEntites {
  total: number;
  value: SchoolEntity[];
}

export function useSchoolEntitiesList() {
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [schoolEntities, setSchoolEntities] = useState<SchoolEntites>({
    total: 0,
    value: [],
  });
  const [page, setPage] = useState<number>(0);

  const fetchSchoolEntities = useCallback((offset: number) => {
    setLoading(true);

    getSchoolEntities(limit, offset).subscribe(({ data }) => {
      setLoading(false);

      setSchoolEntities({
        ...data,
        value: data.schoolEntities || [],
      });
    });
  }, []);

  useEffect(() => {
    const offset = page * limit;
    fetchSchoolEntities(offset);
  }, [page, limit, fetchSchoolEntities]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return {
    schoolEntities: schoolEntities.value,
    loading,
    fetchSchoolEntities,
    page,
    onPageChange,
    schoolEntitiesCount: schoolEntities.total,
    limit,
    setLimit,
  };
}

export function useEntityPaySettings(schoolId: string, entityId: string) {
  const [paySettings, setPaySettings] = useState<PaySetting>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGetEntityPaySettings(schoolId, entityId).subscribe(({ data }) => {
      setLoading(false);
      setPaySettings(data);
    });
  }, []);

  const update = useCallback(
    (settingsId: string, settings: Partial<PaySetting>) => {
      setLoading(true);
      const observable = updateEntityPaySettings(
        entityId,
        schoolId,
        settingsId,
        settings
      );

      observable.subscribe(({ data }) => {
        setPaySettings(data);
        setLoading(false);
      });

      return observable;
    },
    [schoolId, entityId]
  );

  return {
    paySettings,
    loading,
    update,
  };
}
