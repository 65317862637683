import { ApexAccountType } from "models/ApexAccount";
import { get, getQueryStringForProperty, post } from "./base";

export const getApexInternalBalanceByUserId = (
  id: string,
  type: ApexAccountType
) => {
  return get(`/admin/users/${id}/apex/balance/internal`, { type });
};

export const getApexAccountByUserId = (id: string, type: ApexAccountType) => {
  return get(`/admin/users/${id}/apex/account`, { type });
};

export const getApexTransactionHistoryByUserId = (
  id: string,
  type: ApexAccountType
) => {
  return get(`/admin/users/${id}/apex/ach_transfers`, { type });
};

export const closeApexAccount = (userId: string) => {
  return post(`/admin/users/${userId}/apex/account/close`);
};

export const getApexAccountByUserIds = (userIds: string[]) => {
  return get(
    `/admin/users/batch/apex/account?${getQueryStringForProperty(
      "userId",
      userIds
    )}`
  );
};

export const getAccountRequest = (id: string, accountType: ApexAccountType) => {
  return get(
    `/admin/users/${id}/apex/account/status?include=investigation&include=identity_documents&include=first_investment_initiated&type=${accountType}`
  );
};

export const getAccountSettings = (id: string) => {
  return get(`/admin/users/${id}/apex/account_settings`);
};

export const retryAccountRequest = (
  id: string,
  accountType: ApexAccountType
) => {
  return post(`/admin/users/${id}/apex/account/retry`, true, {
    accountType,
  });
};
