import { Add } from "@mui/icons-material";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  createEntityPaySettings,
  createSchoolEntity,
} from "api/schoolEntities";
import useForm from "components/Form/Form.hooks";
import DialogModal from "components/Modals/DialogModal";
import { School } from "models/School";
import { useState } from "react";

interface Props {
  onCreate: () => void;
  school: School;
}

const zeroedTime = {
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
};

export default function CreateSchoolEntityDialog({ onCreate, school }: Props) {
  const [loading, setLoading] = useState(false);
  const { handleFormChange, formValues } = useForm({
    schoolId: school.id,
  });
  const paySettingsForm = useForm({
    anchorDate: moment.utc().add(1, "day").set(zeroedTime),
    paymentFrequency: "monthly",
  });
  const { name } = formValues;

  const title = `Add "${school.displayName}" School Entity`;

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => {
        return (
          <LoadingButton
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => setOpen(true)}
          >
            Add Entity
          </LoadingButton>
        );
      }}
      renderDialog={(_, setOpen) => {
        return (
          <Box minWidth={400}>
            <Card>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <Box sx={{ display: "grid", rowGap: "10px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name || ""}
                    name="name"
                    onChange={handleFormChange}
                  />
                  <Divider textAlign="left">Pay Settings</Divider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled={loading}
                      label="Anchor Date"
                      value={moment.utc(paySettingsForm.formValues?.anchorDate)}
                      shouldDisableDate={(day) => {
                        return day.isBefore(moment());
                      }}
                      onChange={(value) => {
                        paySettingsForm.setForm(
                          "anchorDate",
                          value?.set(zeroedTime)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} margin="dense" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl fullWidth>
                    <InputLabel id="frequency-label">
                      Payment Frequency
                    </InputLabel>
                    <Select
                      disabled={loading}
                      id="frequency"
                      labelId="frequency-label"
                      label="Payment Frequency"
                      value={
                        paySettingsForm.formValues?.paymentFrequency ||
                        "monthly"
                      }
                      name="paymentFrequency"
                      onChange={paySettingsForm.handleFormChange}
                    >
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="biweekly">Biweekly</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    createSchoolEntity(formValues).subscribe(({ data }) => {
                      createEntityPaySettings(
                        data.id,
                        school.id,
                        paySettingsForm.formValues
                      ).subscribe(() => {
                        setOpen(false);
                        setLoading(false);
                        onCreate();
                      });
                    });
                  }}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Card>
          </Box>
        );
      }}
      onClose={() => {}}
    />
  );
}
