import {
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { AxiosResponse } from "axios";
import useForm from "components/Form/Form.hooks";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import EditPage from "components/Pages/EditPage";
import { School } from "models/School";
import { SchoolEntity } from "models/SchoolEntity";
import CreateSchoolEntityDialog from "views/SchoolEntities/CreateSchoolEntityDialog";
import { Team } from "models/Team";
import { useNavigate } from "react-router-dom";

interface Props {
  school: School;
  entities: SchoolEntity[];
  teams: Team[];
  fetchEntities: () => void;
  entitiesLoading: boolean;
  update: (conference: Partial<School>) => Observable<AxiosResponse<any, any>>;
}

const paperStyles = { padding: "20px", marginBottom: "20px" };

export default function SchoolDetailsPage({
  school,
  entities,
  teams,
  fetchEntities,
  entitiesLoading,
  update,
}: Props) {
  const navigate = useNavigate();
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const { formValues, handleFormChange, setForm, setFormValues } =
    useForm(school);

  useEffect(() => {
    setFormValues(school);
  }, [school]);

  return (
    <EditPage
      updateInProgress={updateInProgress}
      heading={`School "${school.displayName}"`}
      onClear={() => {
        setFormValues(school);
      }}
      onSave={() => {
        setUpdateInProgress(true);
        update(formValues).subscribe(() => {
          setUpdateInProgress(false);
        });
      }}
    >
      <Paper sx={paperStyles}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          <TextField
            id="displayName"
            type="text"
            name="displayName"
            label="School Display Name"
            variant="standard"
            value={formValues?.displayName || ""}
            onChange={handleFormChange}
          />
          <TextField
            id="logoUrl"
            type="text"
            name="logoUrl"
            label="Logo URL"
            variant="standard"
            value={formValues.logoUrl}
            onChange={handleFormChange}
          />
          <TextField
            id="abbr"
            type="text"
            name="abbr"
            label="Abbreviation"
            variant="standard"
            value={formValues.abbr}
            onChange={handleFormChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.deleted}
                name="deleted"
                onChange={(e) => {
                  const { checked } = e.target;

                  setForm("deleted", checked);
                }}
              />
            }
            label="Deleted"
          />
          <Typography>Created At: {formValues.createdAt}</Typography>
          <Typography>Updated At: {formValues.updatedAt}</Typography>
        </Box>
      </Paper>

      <Paper sx={paperStyles}>
        <Typography variant="h6">Teams</Typography>
        <List>
          {teams.map((team) => {
            return (
              <ListItem key={team.id}>
                <ListItemText primary={team.displayName} />
              </ListItem>
            );
          })}
        </List>
      </Paper>

      {entitiesLoading && <LinearProgress />}
      <Paper sx={paperStyles}>
        <Typography variant="h6">Entities</Typography>
        <List>
          {entities.map((entity) => {
            return (
              <ListItem key={entity.id}>
                <ListItemText primary={entity.name} />
                <IconButton
                  color="primary"
                  onClick={() => navigate(`/school-entities/${entity.id}`)}
                >
                  <EditIcon></EditIcon>
                </IconButton>
              </ListItem>
            );
          })}
        </List>
        <CreateSchoolEntityDialog school={school} onCreate={fetchEntities} />
      </Paper>
    </EditPage>
  );
}
