import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { createSchool } from "api/schools";
import useForm from "components/Form/Form.hooks";
import DialogModal from "components/Modals/DialogModal";
import { Sport } from "models/Sport";

interface Props {
  onCreate: () => void;
  sports: Sport[];
}
const TITLE = "Create School";

export default function CreateSchoolDialog({ onCreate, sports }: Props) {
  const { handleFormChange, formValues } = useForm({
    displayName: "",
    logoUrl: "",
    abbr: "",
    teamName: "",
    sportsId: "",
  });
  const { displayName, logoUrl, abbr, teamName, sportsId } = formValues;

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => {
        return (
          <Button variant="contained" onClick={() => setOpen(true)}>
            {TITLE}
          </Button>
        );
      }}
      renderDialog={(_, setOpen) => {
        return (
          <Box minWidth={400}>
            <Card>
              <DialogTitle>{TITLE}</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="displayName"
                  label="Display Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={displayName}
                  name="displayName"
                  onChange={handleFormChange}
                />
                <TextField
                  margin="dense"
                  id="logoUrl"
                  label="Logo Url"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={logoUrl}
                  name="logoUrl"
                  onChange={handleFormChange}
                />
                <TextField
                  id="abbr"
                  label="Abbreviation"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={abbr}
                  name="abbr"
                  onChange={handleFormChange}
                />
                <Divider textAlign="left" sx={{ marginTop: "10px" }}>
                  Default team configuration
                </Divider>
                <TextField
                  margin="dense"
                  id="team-name"
                  label="Default team name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={teamName}
                  name="teamName"
                  onChange={handleFormChange}
                />
                <FormControl sx={{ marginTop: "10px" }} fullWidth>
                  <InputLabel id="sports">Team sport</InputLabel>
                  <Select
                    label="Default school team sport"
                    id="sports-select"
                    labelId="sports"
                    name="sportsId"
                    value={sportsId}
                    onChange={handleFormChange}
                  >
                    {sports.map((sport) => {
                      return (
                        <MenuItem value={sport.id} key={sport.id}>
                          {sport.displayName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    createSchool(formValues).subscribe(() => {
                      setOpen(false);
                      onCreate();
                    });
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Card>
          </Box>
        );
      }}
      onClose={() => {}}
    />
  );
}
