import { updateSchoolEntity, getSchoolEntity } from "api/schoolEntities";
import { School } from "models/School";
import { SchoolEntity } from "models/SchoolEntity";
import { useCallback, useEffect, useState } from "react";

export default function useSchoolEntity(id: string) {
  const [schoolEntity, setSchoolEntity] = useState<SchoolEntity>();
  const [loading, setLoading] = useState(true);

  const update = useCallback(
    (values: Partial<School>) => {
      const observable = updateSchoolEntity(id, values);

      observable.subscribe(({ data }) => {
        setSchoolEntity(data);
      });

      return observable;
    },
    [id, schoolEntity]
  );

  useEffect(() => {
    getSchoolEntity(id).subscribe(({ data }) => {
      setLoading(false);
      setSchoolEntity(data);
    });
  }, []);

  return {
    loading,
    schoolEntity,
    update,
  };
}
