import {
  getConference,
  getConferenceSchools,
  updateConference,
} from "api/conferences";
import { Conference } from "models/Conference";
import { School } from "models/School";
import { useCallback, useEffect, useState } from "react";

export default function useConference(id: string) {
  const [conference, setConference] = useState<Conference>();
  const [loading, setLoading] = useState(true);
  const [connectedSchools, setConnectedSchools] = useState<School[]>([]);

  const fetch = () => {
    getConference(id).subscribe(({ data }) => {
      setLoading(false);
      setConference(data);
    });
    getConferenceSchools(id).subscribe(({ data }) => {
      setConnectedSchools(data.schools || []);
    });
  };

  const update = useCallback(
    (values: Partial<Conference>) => {
      const observable = updateConference(id, values);

      observable.subscribe(({ data }) => {
        setConference(data);
      });

      return observable;
    },
    [id, conference]
  );

  useEffect(fetch, []);

  return {
    loading,
    conference,
    update,
    connectedSchools,
    reload: fetch,
  };
}
