import { closeApexAccount } from "api/accounts";
import { getAprilApplicationLink } from "api/april";
import { syncToHubspot } from "api/hubspot";
import { blockUser, unblockUser } from "api/users";
import { useSuperAdminContext } from "components/Contexts/SuperAdminContext/SuperAdminContext";
import useUser from "components/Hooks/User.hooks";
import { useState } from "react";

export default function useOverflowMenuDialog(
  userId: string,
  closeDialog: () => void,
  onEdit: () => void
) {
  const superAdminToken = useSuperAdminContext();
  const user = useUser(userId);
  const [applicationLink, setApplicationLink] = useState<string | null>(null);

  const toggleBlocked = () => {
    if (user) {
      user.blocked
        ? unblockUser(userId).subscribe(onEdit)
        : blockUser(userId).subscribe(onEdit);
      closeDialog();
    }
  };

  const closeAccount = () => {
    closeApexAccount(userId).subscribe(onEdit);
  };

  const syncHubspot = () => {
    syncToHubspot([userId]);
  };

  const getLink = () => {
    getAprilApplicationLink(userId).subscribe(({ data }) => {
      console.log(data);
      setApplicationLink(data.url);
    });
  };

  const sendChat = () => {
    window.open(`/chat/${userId}-advisor-channel`, "_blank");
  };

  return {
    superAdminToken,
    user,
    toggleBlocked,
    closeAccount,
    syncHubspot,
    sendChat,
    getLink,
    applicationLink,
  };
}
