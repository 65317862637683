import { Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Page from "components/Common/Page";
import { useNavigate } from "react-router-dom";
import useSports from "views/Sports/Sports.hooks";
import CreateSchoolDialog from "./CreateSchoolDialog";
import useSchoolList from "./SchoolList.hooks";

const columns: GridColDef[] = [
  { field: "displayName", headerName: "Display Name", width: 260 },
  { field: "abbr", headerName: "Abbreviation" },
  { field: "deleted", headerName: "Deleted" },
  { field: "logoUrl", headerName: "Logo URL" },
  { field: "createdAt", headerName: "Created At", width: 180 },
  { field: "updatedAt", headerName: "Updated At", width: 180 },
];

export default function SchoolsListPage() {
  const navigate = useNavigate();
  const {
    schools,
    fetchSchools,
    page,
    loading,
    onPageChange,
    limit,
    setLimit,
  } = useSchoolList();
  const { sports } = useSports();

  return (
    <Page
      heading="Schools"
      RightHeadingComponent={
        <CreateSchoolDialog
          sports={sports}
          onCreate={() => {
            fetchSchools(page);
          }}
        />
      }
    >
      <Paper sx={{ width: "100%" }}>
        <DataGrid
          sx={{
            "--DataGrid-overlayHeight": "300px",
            ".MuiDataGrid-cell": {
              cursor: "pointer",
            },
          }}
          autoHeight
          columns={columns}
          rows={schools}
          loading={loading}
          pageSize={limit}
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => setLimit(pageSize)}
          onCellClick={({ id }) => navigate(`/schools/${id}`)}
        ></DataGrid>
      </Paper>
    </Page>
  );
}
