import { getAllSports } from "api/sports";
import { Sport } from "models/Sport";
import { useEffect, useState } from "react";

export default function useSports() {
  const [sports, setSports] = useState<Sport[]>([]);

  useEffect(() => {
    getAllSports().subscribe(({ data }) => {
      setSports(data);
    });
  }, []);

  return { sports };
}
