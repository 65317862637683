import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import useForm from "components/Form/Form.hooks";
import { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Observable } from "rxjs";
import EditPage from "components/Pages/EditPage";
import { SchoolEntity } from "models/SchoolEntity";
import { useEntityPaySettings } from "./SchoolEntities.hooks";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

interface Props {
  schoolEntity: SchoolEntity;
  update: (
    conference: Partial<SchoolEntity>
  ) => Observable<AxiosResponse<any, any>>;
}

const paperStyles = { padding: "20px", marginBottom: "20px" };

export default function SchoolEntityDetailsPage({
  schoolEntity,
  update,
}: Props) {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const { formValues, handleFormChange, setForm, setFormValues } =
    useForm(schoolEntity);

  const {
    paySettings,
    loading,
    update: updatePaySettings,
  } = useEntityPaySettings(schoolEntity.schoolId as string, schoolEntity.id);

  const paySettingsForm = useForm(paySettings);

  useEffect(() => {
    paySettingsForm.setFormValues(paySettings);
  }, [paySettings]);

  useEffect(() => {
    setFormValues(schoolEntity);
  }, [schoolEntity]);

  return (
    <EditPage
      updateInProgress={updateInProgress}
      heading={`Entity "${schoolEntity.name}"`}
      onClear={() => {
        setFormValues(schoolEntity);
      }}
      onSave={() => {
        setUpdateInProgress(true);
        update(formValues).subscribe(() => {
          setUpdateInProgress(false);
        });

        if (paySettingsForm.formValues?.id) {
          const updatedSettings = { ...paySettingsForm.formValues };

          delete updatedSettings["anchorDate"];

          updatePaySettings(
            paySettingsForm.formValues.id as string,
            updatedSettings
          );
          return;
        }
      }}
    >
      <Paper sx={paperStyles}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          <TextField
            id="name"
            type="text"
            name="name"
            label="Entity Name"
            variant="standard"
            value={formValues?.name || ""}
            onChange={handleFormChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.deleted}
                name="deleted"
                onChange={(e) => {
                  const { checked } = e.target;

                  setForm("deleted", checked);
                }}
              />
            }
            label="Deleted"
          />
          <Typography>Created At: {formValues.createdAt}</Typography>
          <Typography>Updated At: {formValues.updatedAt}</Typography>
        </Box>
      </Paper>

      {loading ? <LinearProgress /> : <></>}
      <Paper sx={paperStyles}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginBottom: "20px" }}
        >
          <Typography variant="h6">Pay Settings</Typography>
        </Box>
        <Box maxWidth={300} sx={{ display: "grid", rowGap: "10px" }}>
          {!loading && paySettings == null && (
            <Typography variant="body2">
              "{schoolEntity.name}" doesn't have its pay settings configured
              yet.
            </Typography>
          )}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled
              label="Anchor Date"
              value={moment.utc(paySettingsForm.formValues?.anchorDate)}
              disablePast
              onChange={(value) => {
                paySettingsForm.setForm(
                  "anchorDate",
                  value?.set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                );
              }}
              renderInput={(params) => (
                <TextField {...params} margin="dense" fullWidth />
              )}
            />
          </LocalizationProvider>
          <FormControl fullWidth>
            <InputLabel id="frequency-label">Payment Frequency</InputLabel>
            <Select
              disabled={loading}
              id="frequency"
              labelId="frequency-label"
              label="Payment Frequency"
              value={paySettingsForm.formValues?.paymentFrequency || "monthly"}
              name="paymentFrequency"
              onChange={paySettingsForm.handleFormChange}
            >
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="biweekly">Biweekly</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>
    </EditPage>
  );
}
