import axios, { AxiosInstance } from "axios";
import config from "config";
import { catchError, from, of } from "rxjs";
import { getLocalStorageValue, LocalStorageProp } from "utils/localStorage";

const AangAPI = axios.create({ baseURL: config.data });
export const ZukoAPI = axios.create({ baseURL: config.zuko });

export enum ErrorType {
  NotAuthenticated = "not authenticated",
  InvalidToken = "invalid token",
}

export const getAuthToken = (): string => {
  const token = getLocalStorageValue(LocalStorageProp.Token);

  if (!token) {
    throw new Error(ErrorType.NotAuthenticated);
  }

  return token;
};

export const get = (
  route: string,
  params?: { [key: string]: any },
  API?: AxiosInstance
) => {
  const token = getAuthToken();

  return from(
    (API || AangAPI).get(route, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).pipe(
    catchError((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            window.open("/login", "_self");
            break;
          case 403:
            window.open("/unauthorized", "_self");
            break;
          case 404:
            return of({ data: null });
          default:
            break;
        }
      }
      throw err;
    })
  );
};

export const post = (
  route: string,
  authed: boolean = true,
  body?: { [key: string]: any },
  API?: AxiosInstance
) => {
  let token;
  if (authed) {
    token = getAuthToken();
  }

  return from(
    (API || AangAPI).post(route, body, {
      headers: authed
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    })
  );
};

export const patch = (
  route: string,
  authed: boolean = true,
  body?: { [key: string]: any }
) => {
  let token;
  if (authed) {
    token = getAuthToken();
  }

  return from(
    AangAPI.patch(route, body, {
      headers: authed
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    })
  );
};

export const apidelete = (route: string, authed: boolean = true) => {
  let token;
  if (authed) {
    token = getAuthToken();
  }

  return from(
    AangAPI.delete(route, {
      headers: authed
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    })
  );
};

export const getQueryStringForProperty = (
  property: string,
  values: string[]
) => {
  return values.map((val) => `${property}=${val}`).join("&");
};

export const getDocument = (route: string, params?: { [key: string]: any }) => {
  const token = getAuthToken();

  return from(
    AangAPI.get(route, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
  ).pipe((response) => {
    response.subscribe({
      error: (err) => {
        switch (err.response.status) {
          case 401:
            window.open("/login", "_self");
            break;
          case 403:
            window.open("/unauthorized", "_self");
            break;
          default:
            break;
        }
      },
    });
    return response;
  });
};
