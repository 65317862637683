import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import useForm from "components/Form/Form.hooks";
import { Conference } from "models/Conference";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import EditPage from "components/Pages/EditPage";
import { School } from "models/School";
import AddSchoolDialog from "./AddSchoolDialog";
import { getAllSchools } from "api/schools";
import { LoadingButton } from "@mui/lab";
import { RemoveCircleOutline } from "@mui/icons-material";
import { detachSchool } from "api/conferences";

interface Props {
  conference: Conference;
  update: (
    conference: Partial<Conference>
  ) => Observable<AxiosResponse<any, any>>;
  reload: () => void;
  connectedSchools: School[];
}

const paperStyles = { padding: "20px", marginBottom: "20px" };

function SchoolItem({
  conference,
  school,
  reload,
}: {
  conference: Conference;
  school: School;
  reload: () => void;
}) {
  const [loading, setLoading] = useState(false);
  return (
    <ListItem>
      <ListItemText primary={school.displayName} />
      <LoadingButton
        color="error"
        loading={loading}
        onClick={() => {
          setLoading(true);
          detachSchool(conference.id, school.id).subscribe(() => {
            setLoading(false);
            reload();
          });
        }}
      >
        <RemoveCircleOutline />
      </LoadingButton>
    </ListItem>
  );
}

export default function ConferenceDetailsPage({
  conference,
  update,
  reload,
  connectedSchools,
}: Props) {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const { formValues, handleFormChange, setForm, setFormValues } =
    useForm(conference);

  const [availableSchools, setAvailableSchools] = useState<School[]>([]);

  useEffect(() => {
    setFormValues(conference);

    getAllSchools().subscribe(({ data }) => {
      const schools = (data.schools || []) as School[];

      const ignoredIds = connectedSchools.map((school) => school.id);

      const filtered = schools.filter((school) => {
        return !ignoredIds.includes(school.id);
      });

      setAvailableSchools(filtered);
    });
  }, [conference]);

  return (
    <EditPage
      updateInProgress={updateInProgress}
      heading={`Conference "${conference.name}"`}
      onClear={() => {
        setFormValues(conference);
      }}
      onSave={() => {
        setUpdateInProgress(true);
        update(formValues).subscribe(() => {
          setUpdateInProgress(false);
        });
      }}
    >
      <Paper sx={paperStyles}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          <TextField
            id="name"
            type="text"
            name="name"
            label="Conference Name"
            variant="standard"
            value={formValues?.name || ""}
            onChange={handleFormChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.deleted}
                name="deleted"
                onChange={(e) => {
                  const { checked } = e.target;

                  setForm("deleted", checked);
                }}
              />
            }
            label="Deleted"
          />
          <Typography>Created At: {formValues.createdAt}</Typography>
          <Typography>Updated At: {formValues.updatedAt}</Typography>
        </Box>
      </Paper>
      <Paper sx={paperStyles}>
        <Typography variant="h6">Schools:</Typography>
        <List>
          {connectedSchools.map((school) => (
            <SchoolItem
              key={school.id}
              conference={conference}
              school={school}
              reload={reload}
            />
          ))}
        </List>
        <AddSchoolDialog
          conferenceId={conference.id}
          onCreate={reload}
          schoolChoices={availableSchools}
        />
      </Paper>
    </EditPage>
  );
}
