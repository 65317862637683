import { CreateSchoolRequest, School } from "models/School";
import { get, post } from "./base";

export const getSchools = (limit?: number, offset?: number) => {
  return get("/admin/schools", { limit: limit, offset: offset });
};

export const getAllSchools = () => {
  return get("/admin/schools/all");
};

export const getSchoolEntities = (schoolId: string) => {
  return get(`/admin/schools/${schoolId}/entities`);
};

export const getSchoolTeams = (schoolId: string) => {
  return get(`/admin/schools/${schoolId}/teams`);
};

export const getSchool = (id: string) => {
  return get(`/admin/schools/${id}`);
};

export const createSchool = (school: CreateSchoolRequest) => {
  return post("/admin/schools", true, school);
};

export const updateSchool = (id: string, fields: Partial<School>) => {
  return post(`/admin/schools/${id}`, true, fields);
};
