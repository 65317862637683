import { Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Page from "components/Common/Page";
import { useNavigate } from "react-router-dom";
import { useSchoolEntitiesList } from "./SchoolEntities.hooks";

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 260 },
  { field: "schoolName", headerName: "School", width: 260 },
  { field: "deleted", headerName: "Deleted" },
  { field: "createdAt", headerName: "Created At", width: 180 },
  { field: "updatedAt", headerName: "Updated At", width: 180 },
];

export default function SchoolEntitesListPage() {
  const navigate = useNavigate();
  const { schoolEntities, loading, onPageChange, limit, setLimit } =
    useSchoolEntitiesList();

  return (
    <Page heading="School Entities">
      <Paper sx={{ width: "100%" }}>
        <DataGrid
          sx={{
            "--DataGrid-overlayHeight": "300px",
            ".MuiDataGrid-cell": {
              cursor: "pointer",
            },
          }}
          autoHeight
          columns={columns}
          rows={schoolEntities}
          loading={loading}
          pageSize={limit}
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => setLimit(pageSize)}
          onCellClick={({ id }) => navigate(`/school-entities/${id}`)}
        ></DataGrid>
      </Paper>
    </Page>
  );
}
