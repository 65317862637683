import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import Page from "components/Common/Page";

interface Props {
  loading: boolean;
  found: boolean;
  notFoundTitle: string;
}

export default function PageLoader({ loading, found, notFoundTitle }: Props) {
  if (loading) {
    return (
      <Page>
        <CircularProgress />
      </Page>
    );
  }

  if (!found) {
    return (
      <Page>
        <Box>{notFoundTitle}</Box>
      </Page>
    );
  }

  return null;
}
