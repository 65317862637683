import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { addSchoolToConference } from "api/conferences";
import useForm from "components/Form/Form.hooks";
import DialogModal from "components/Modals/DialogModal";
import { School } from "models/School";
import { useState } from "react";

interface Props {
  conferenceId: string;
  onCreate: () => void;
  schoolChoices: School[];
}
const TITLE = "Add School";

export default function AddSchoolDialog({
  onCreate,
  conferenceId,
  schoolChoices,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { handleFormChange, formValues } = useForm({ schoolId: "" });
  const { schoolId } = formValues;

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => {
        return (
          <Button variant="contained" onClick={() => setOpen(true)}>
            {TITLE}
          </Button>
        );
      }}
      renderDialog={(_, setOpen) => {
        return (
          <Box minWidth={400}>
            <Card>
              <DialogTitle>{TITLE}</DialogTitle>
              <DialogContent>
                <FormControl fullWidth>
                  <InputLabel id="add-school-label">Select School</InputLabel>
                  <Select
                    id="add-school"
                    labelId="add-school-label"
                    label="Select School"
                    name="schoolId"
                    value={schoolId}
                    onChange={handleFormChange}
                  >
                    {schoolChoices.map((school) => {
                      return (
                        <MenuItem value={school.id} key={school.id}>
                          {school.displayName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  fullWidth
                  loading={loading}
                  variant="contained"
                  onClick={() => {
                    setLoading(true);
                    addSchoolToConference(conferenceId, schoolId).subscribe(
                      () => {
                        setLoading(false);
                        setOpen(false);
                        onCreate();
                      }
                    );
                  }}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Card>
          </Box>
        );
      }}
      onClose={() => {}}
    />
  );
}
