import PageLoader from "components/Pages/PageLoader";
import { Route, Routes, useParams } from "react-router-dom";
import useSchool from "./School.hooks";
import SchoolDetailsPage from "./SchoolDetailsPage";
import SchoolsListPage from "./SchoolsListPage";

const SchoolPage = () => {
  const { id } = useParams();
  const {
    loading,
    school,
    update,
    entities,
    teams,
    fetchEntities,
    entitiesLoading,
  } = useSchool(id as string);

  if (loading || !school) {
    return (
      <PageLoader
        loading={loading}
        found={school != null}
        notFoundTitle="School Not Found"
      />
    );
  }

  return (
    <SchoolDetailsPage
      school={school}
      update={update}
      entities={entities}
      teams={teams}
      fetchEntities={fetchEntities}
      entitiesLoading={entitiesLoading}
    />
  );
};

export default function SchoolRoutes() {
  return (
    <Routes>
      <Route key="schools" path="/" element={<SchoolsListPage />}></Route>
      <Route key="school-details" path="/:id" element={<SchoolPage />}></Route>
    </Routes>
  );
}
