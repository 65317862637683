import PageLoader from "components/Pages/PageLoader";
import { Route, Routes, useParams } from "react-router-dom";
import useConference from "./Conference.hooks";
import ConferenceDetailsPage from "./ConferenceDetailsPage";
import ConferencesListPage from "./ConferencesListPage";

const ConferencePage = () => {
  const { id } = useParams();
  const { loading, conference, connectedSchools, update, reload } =
    useConference(id as string);

  if (loading || !conference) {
    return (
      <PageLoader
        loading={loading}
        found={conference != null}
        notFoundTitle="Conference Not Found"
      />
    );
  }

  return (
    <ConferenceDetailsPage
      conference={conference}
      update={update}
      reload={reload}
      connectedSchools={connectedSchools}
    />
  );
};

export default function ConferenceRoutes() {
  return (
    <Routes>
      <Route key="conferences" path="/" element={<ConferencesListPage />} />
      <Route
        key="conference-details"
        path="/:id"
        element={<ConferencePage />}
      ></Route>
    </Routes>
  );
}
