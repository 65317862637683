import { SchoolEntity, PaySetting } from "models/SchoolEntity";
import { get, post, patch } from "./base";

export const getSchoolEntities = (limit?: number, offset?: number) => {
  return get("/admin/entities", { limit: limit, offset: offset });
};

export const getSchoolEntity = (id: string) => {
  return get(`/admin/entities/${id}`);
};

export const createSchoolEntity = (schoolEntity: Partial<SchoolEntity>) => {
  return post("/admin/entities", true, schoolEntity);
};

export const updateSchoolEntity = (
  id: string,
  schoolEntity: Partial<SchoolEntity>
) => {
  return post(`/admin/entities/${id}`, true, schoolEntity);
};

export const createEntityPaySettings = (
  entityId: string,
  schoolId: string,
  setting: Partial<PaySetting>
) => {
  return post(
    `/admin/schools/${schoolId}/entities/${entityId}/pay_settings`,
    true,
    setting
  );
};

export const updateEntityPaySettings = (
  entityId: string,
  schoolId: string,
  settingsId: string,
  setting: Partial<PaySetting>
) => {
  return patch(
    `/admin/schools/${schoolId}/entities/${entityId}/pay_settings/${settingsId}`,
    true,
    setting
  );
};

export const getGetEntityPaySettings = (schoolId: string, entityId: string) => {
  return get(`/admin/schools/${schoolId}/entities/${entityId}/pay_settings`);
};
