import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Loader from "components/Common/Loader";
import Spacer from "components/Common/Spacer";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";

interface IProps {
  onClose: () => void;
  heading: string;
  getDetails: () => Observable<string>;
  open: boolean;
  onRetry?: () => void;
}

export default function DetailsDialog({
  open,
  onClose,
  getDetails,
  heading,
  onRetry,
}: IProps) {
  const [details, setDetails] = useState<string>();

  useEffect(() => {
    if (open) {
      getDetails().subscribe((d) => setDetails(d));
    }
  }, [open]);

  if (open && !details) {
    return <Loader />;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        <pre>{details}</pre>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="row" width="100%" minWidth={300}>
          <Button
            variant="contained"
            onClick={onClose}
            fullWidth
            color="inherit"
          >
            Got it
          </Button>
          {onRetry && (
            <>
              <Spacer width={12} />
              <Button variant="contained" onClick={onRetry} fullWidth>
                Retry Account Request
              </Button>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
