import { Link } from "@mui/material";
import { getAccountRequest, retryAccountRequest } from "api/accounts";
import DetailsDialog from "components/Modals/DetailsDialog";
import DialogModal from "components/Modals/DialogModal";
import { noop } from "lodash";
import { ApexAccountType } from "models/ApexAccount";
import { map } from "rxjs/operators";

interface IProps {
  accountType: ApexAccountType;
  userId: string;
  allowRetry?: boolean;
}

export default function AccountRequestDialog({
  userId,
  accountType,
  allowRetry,
}: IProps) {
  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Link onClick={() => setOpen(true)}>See Detail View</Link>
      )}
      renderDialog={(open, setOpen) => (
        <DetailsDialog
          open={open}
          onClose={() => setOpen(false)}
          heading="Account Request"
          getDetails={() =>
            getAccountRequest(userId, accountType).pipe(
              map(({ data }) => JSON.stringify(data, null, 2))
            )
          }
          onRetry={
            allowRetry
              ? () =>
                  retryAccountRequest(userId, accountType).subscribe(() =>
                    window.location.reload()
                  )
              : undefined
          }
        />
      )}
      onClose={noop}
    />
  );
}
