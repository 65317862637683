import Page from "components/Common/Page";
import { ReactNode } from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { bool } from "aws-sdk/clients/signer";

interface EditPageProps {
  heading?: string;
  children?: ReactNode;
  onClear: () => void;
  onSave: () => void;
  updateInProgress: bool;
}

export default function EditPage({
  children,
  heading,
  onClear,
  onSave,
  updateInProgress,
}: EditPageProps) {
  return (
    <Page heading={heading}>
      <Box maxWidth="800px">
        <Box>{children}</Box>
        <Box display="flex">
          <Button
            variant="outlined"
            sx={{ marginRight: "8px" }}
            onClick={onClear}
          >
            Clear
          </Button>

          <LoadingButton
            variant="contained"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            loading={updateInProgress}
            onClick={onSave}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Page>
  );
}
