import { Conference } from "models/Conference";
import { apidelete, get, post } from "./base";

export const getConferences = (limit?: number, offset?: number) => {
  return get("/admin/conferences", { limit: limit, offset: offset });
};

export const getConference = (id: string) => {
  return get(`/admin/conference/${id}`);
};

export const createConference = (name: string) => {
  return post("/admin/conferences", true, { name });
};

export const addSchoolToConference = (
  conferenceId: string,
  schoolId: string
) => {
  return post(`/admin/conferences/${conferenceId}/schools/${schoolId}`);
};

export const detachSchool = (conferenceId: string, schoolId: string) => {
  return apidelete(`/admin/conferences/${conferenceId}/schools/${schoolId}`);
};

export const updateConference = (id: string, fields: Partial<Conference>) => {
  return post(`/admin/conference/${id}`, true, { ...fields });
};

export const getConferenceSchools = (conferenceId: string) => {
  return get(`/admin/conference/${conferenceId}/schools`);
};
