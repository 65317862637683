import { getSchools } from "api/schools";
import { School } from "models/School";
import { useCallback, useEffect, useState } from "react";

interface Schools {
  total: number;
  value: School[];
}

export default function useSchoolList() {
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [schools, setSchools] = useState<Schools>({
    total: 0,
    value: [],
  });
  const [page, setPage] = useState<number>(0);

  const fetchSchools = useCallback((offset: number) => {
    setLoading(true);

    getSchools(limit, offset).subscribe(({ data }) => {
      setLoading(false);

      setSchools({
        ...data,
        value: data.schools,
      });
    });
  }, []);

  useEffect(() => {
    const offset = page * limit;
    fetchSchools(offset);
  }, [page, limit, fetchSchools]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return {
    schools: schools.value,
    loading,
    fetchSchools,
    page,
    onPageChange,
    schoolsCount: schools.total,
    limit,
    setLimit,
  };
}
