import PageLoader from "components/Pages/PageLoader";
import { Route, Routes, useParams } from "react-router-dom";
import SchoolEntitesListPage from "./SchoolEntitiesListPage";
import useSchoolEntity from "./SchoolEntity.hooks";
import SchoolEntityDetailsPage from "./SchoolEntityDetailsPage";

const EntityPage = () => {
  const { id } = useParams();
  const { loading, schoolEntity, update } = useSchoolEntity(id as string);

  if (loading || !schoolEntity) {
    return (
      <PageLoader
        loading={loading}
        found={schoolEntity != null}
        notFoundTitle="School Entity Not Found"
      />
    );
  }

  return (
    <SchoolEntityDetailsPage schoolEntity={schoolEntity} update={update} />
  );
};

export default function SchoolEntitiesRoutes() {
  return (
    <Routes>
      <Route
        key="school-entities-list"
        path="/"
        element={<SchoolEntitesListPage />}
      ></Route>
      <Route key="school-entity" path="/:id" element={<EntityPage />}></Route>
    </Routes>
  );
}
